import React, { useEffect, useState, createRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import {
  REACT_APP_API_URL,
  REACT_REACT_APP_API_URL,
  APP_BASE_URL,
  CURE_URL,
  REACT_APP_BU_API_URL,
  APP_URL,
} from "../../Components/processENV";
import Posts from "./Posts";
import caremelogo from "../../assets/images/logo.png";
import { IoIosRewind } from "react-icons/io";
import {
  AiFillPlaySquare,
  AiOutlineArrowRight,
  AiOutlineArrowDown,
} from "react-icons/ai";
import { AiFillCheckSquare } from "react-icons/ai";
import Loader from "../Layouts/Loader";
import { MdArrowUpward } from "react-icons/md";
import { AiFillCloseSquare } from "react-icons/ai";
import communitybanner from "../../assets/images/communitybanner.png";
import communityads from "../../assets/images/communityads.png";
import live from "../../assets/images/live.png";
import {
  ArrowRight,
  Check,
  CheckSquare,
  PlayCircle,
  Square,
} from "react-feather";
import { useRef } from "react";

const CommunityHome = (props) => {
  const [item, setItem] = useState([]);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [check, setCheck] = useState(false);
  const [step, setStep] = useState(0);
  const [useAvatar, setUseAvatar] = useState();
  const [userImg, setuserImg] = useState();
  const [evntImg, setEvntImg] = useState({
    eventimg: "",
    eventurl: "",
  });
  const [nickName, setNickName] = useState();
  const [next, setNext] = useState();
  const [avatar, setAvatar] = useState();
  const [careprofile, setCareProfile] = useState();
  const [userProfile, setUserProfile] = useState();
  const [specificId, setSpecificId] = useState();
  const [grpTopics, setGrpTopics] = useState();
  const [authenticated, setAuthenticated] = useState(false);
  const [load, setLoad] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const divref = useRef();



  useEffect(() => {
    setCheck(false);
    avatar &&
      avatar.map((item) => {
        if (item.id == useAvatar) {
          item.select = true;
          setuserImg(item.src);
          setCheck(true);
        } else {
          item.select = false;
        }
      });
  }, [useAvatar]);

  useEffect(() => {
    
    const auth = localStorage.status;
    if (auth === "completed") {
      setAuthenticated(true);
    }

    let url = window.location.pathname;
    var id = url.split("/")[2];
    if (auth === "completed") {
      userVerify();
      if (id) {
        getTopics(id);
      } else getPosts();
    } else {
      if (id && id.length !== 0) {
        console.log("yes");
        getTopics(id);
      } else {
        console.log("no");
        getCommonPosts();
      }
    }
    getUser();
    getEvntImg();
    getAvatar();
  }, []);


  const setName = (val) => {
    if (val.trim().length >= 3) {
      setNickName(val);
      setCheck(true);
    } else {
      setCheck(false);
    }
  };
  const setNoname = () => {
    setNickName("");
    setCheck(!check);
  };
  const setImg = (id) => {
    setUseAvatar(id);
  };
  const getEvntImg = () => {
    fetch(`${REACT_APP_BU_API_URL}/carme_events/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify({patient_n_key:localStorage._patientKey}),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("resj", res);
        setEvntImg({
          eventimg: res.alldata[0].image_url,
          eventurl: res.alldata[0].url,
        });
      })
      .catch((err) => console.log(err));
  };
  const getUser = () => {
    const data = { user_key: localStorage._patientKey };
    fetch(`${CURE_URL}/community/community_auth_user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("res", res);
        setUserProfile(res.community_data);
      })
      .catch((err) => console.log(err));
  };
  const sendData = () => {
    setStep(step + 1);
    const data = {
      user_key: localStorage._patientKey,
      community_name: nickName ? nickName : "Anonymous",
      avatar: userImg ? userImg : "user",
    };
    fetch(`${CURE_URL}/community/community_user_update/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("user_added", res);
        setStep(null);
      })
      .catch((err) => console.log(err));
  };
  const userVerify = () => {
    const data = {
      user_key: localStorage._patientKey,
    };
    fetch(`${CURE_URL}/community/community_auth_user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("user_Verify", res.community_data.verified);
        if (!res.community_data.verified) {
          setShowGuidelines(true);
        }
        setCareProfile(res.community_data);
      })
      .catch((err) => console.log(err));
  };
  const getAvatar = () => {
    fetch(`${CURE_URL}/community/get_avatar/`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        setAvatar(res.avatar);
      })
      .catch((err) => console.log(err));
  };
  const getCommonPosts = (id, setid) => {
    let key = id;
    const data = {
      page: id ? id : "",
      user_key:localStorage._patientKey||'',
    };
    fetch(`${CURE_URL}/community/user_feed/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("community post", res);
        if (res.status === "success") {
          var feedlist = res.data.map((item) => {
            return {
              id: item.activity_id,
              userName: item.user_name,
              role: item.role,
              userImg: item.user_profile,
              postTime: item.created_on,
              title: item.topic,
              post: item.content.text,
              postImg: item.content.attachments[0]
                ? item.content.attachments[0].image
                : null,
              mediaType: item.content.attachments[0]
                ? item.content.attachments[0].type
                : null,
              liked: item.self_reaction,
              likes: item.reaction_count,
              comments: item.comment_count,
              thumbnail: null,
            };
          });
          console.log(feedlist);
          setNext(res.current_page);
          if (id) {
            let data = item.concat(feedlist);
            console.log(data);
            setItem(data);
          } else {
            setItem(feedlist);
          }
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  };
  const getPosts = (id) => {
    const data = {
      user_key: localStorage._patientKey||'',
      filter_topic: null,
      page: id ? id + 1 : 1,
    };
    fetch(`${CURE_URL}/community/user_feed/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("community post", res);
        if (res.status === "success") {
          var feedlist = res.data.map((item) => {
            return {
              id: item.activity_id,
              userName: item.user_name,
              role: item.role,
              userImg: item.user_profile,
              postTime: item.created_on,
              title: item.topic,
              post: item.content.text,
              postImg: item.content.attachments[0]
                ? item.content.attachments[0].image
                : null,
              mediaType: item.content.attachments[0]
                ? item.content.attachments[0].type
                : null,
              liked: item.self_reaction,
              likes: item.reaction_count,
              comments: item.comment_count,
              thumbnail: null,
            };
          });
          console.log(feedlist);
          setNext(res.current_page);
          if (id) {
            let data = item.concat(feedlist);
            console.log(data);
            setItem(data);
          } else {
            setItem(feedlist);
          }
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  };
  const getTopics = async (id) => {
    fetch(`${CURE_URL}/community/community_topics/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify({ user_key: localStorage._patientKey||''}),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          var arr = res.data;
          let filt = arr.filter((i) => i.topic == decodeURIComponent(id));
          console.log(id, arr.map((i) => i.topic))

          filt&&filt[0]&& getSpeificPost(filt[0]?.topic_id);
        }
      });
  };
  const getSpeificPost = (id) => {
    const data = {
      filter_topic: id,
      user_key:localStorage._patientKey||'',
    };
    console.log(data);
    fetch(`${CURE_URL}/community/user_feed/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("community__post", res);
        if (res.status === "success") {
          var feedlist = res.data.map((item) => {
            return {
              id: item.activity_id,
              userName: item.user_name,
              role: item.role,
              userImg: item.user_profile,
              postTime: item.created_on,
              title: item.topic,
              post: item.content.text,
              postImg: item.content.attachments[0]
                ? item.content.attachments[0].image
                : null,
              mediaType: item.content.attachments[0]
                ? item.content.attachments[0].type
                : null,
              liked: item.self_reaction,
              likes: item.reaction_count,
              comments: item.comment_count,
              thumbnail: null,
            };
          });
          setNext(res.current_page);
          console.log(item);
          if (id) {
            let data = item.concat(feedlist);
            console.log(data);
            setItem(data);
          } else {
            setItem(feedlist);
          }
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  };
  const getGroupPost = async (id, title) => {
     var url = window.location.href;
      if(url==`${APP_URL}/community`|| url==`${APP_URL}/communities`){
      url = url + "/" + title;
      } else {
      let last = url.split("/");
      let ur = last.slice(0, last.length - 1).join("/") + "/" + title;
      url = ur;
    }
    window.location.href = url;
  };
  const checkUsr = () => {
    let auth = localStorage.status;
    if (auth === "completed") {
      window.location.replace("/communities");
    } else {
      window.location.replace("/");
    }
  };

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div className="col-md-12 m-0 p-0">
          <div className="d-flex flex-row">
            <div className="col-md-6">
              <h3
                className="text-center mt-5 pt-5 font-weight-bold h2"
                style={{ color: "#215352", fontSize: "35px" }}
              >
                {" "}
                CareMe Community{" "}
              </h3>
              <h4
                className="text-center mt-1 font-weight-bold h4"
                style={{ color: "#215352", fontSize: "26px" }}
              >
                Share. Be Supported. Recover.{" "}
              </h4>
              <p
                className="text-left ml-md-5 mt-1 font-weight-bold pl-md-5"
                style={{ color: "#215352", fontSize: "15px" }}
              >
                CareMe Health is a safe, online community where people support
                each other by connecting anonymously to improve mental health
                and wellbeing.{" "}
              </p>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <img className="img-fluid " src={communitybanner} />
            </div>
          </div>

          <div className="d-flex col-md-12 justify-content-between px-md-4 px-0">
            <div className="col-md-9 p-md-2 p-0">
              {item && (
                <Posts
                  user={userProfile}
                  item={item}
                  getGroupPost={getGroupPost}
                />
              )}
              <div className="col-md-9 mx-auto text-center my-2">
                {next ? (
                  <button
                    style={{ background: "#215352", color: "#fff" }}
                    className="btn border shadow font-weight-bold"
                    onClick={() => {
                      getPosts(next, "next");
                    }}
                  >
                    Load more <AiOutlineArrowDown size={20} />
                  </button>
                ) : (
                  <button
                    style={{ background: "#215352", color: "#fff" }}
                    className="btn border shadow font-weight-bold"
                  >
                    You caught up all feeds{" "}
                  </button>
                )}
              </div>
            </div>

            <div
              style={{ right: "2px", overflow: "hidden", height: "720px" }}
              className={`col-md-3 d-none d-md-block ads_banner_community`}
            >
              {evntImg?.eventurl && evntImg.eventimg && (
                <div className=" mt-2 ">
                  <h4
                    className=" text-center font-weight-bold"
                    style={{ color: "#215352", fontSize: "25px" }}
                  >
                    <img src={live} width="40" height="40" /> Live Stream
                  </h4>
                  <div className="card">
                    <img src={evntImg.eventimg} className="img-fluid" />
                  </div>
                  <div className="text-center m-2">
                    <a
                      className="mt-5 pt-5"
                      href={evntImg.eventurl}
                      target="_blank"
                      style={{ color: "#215352", fontSize: "15px" }}
                    >
                      Stay Tuned <ArrowRight size={20} />{" "}
                    </a>
                  </div>
                </div>
              )}

              <div
                className="text-center shadow-sm border mt-md-3 px-2 "
                style={{ maxHeight: "420px", background: "#ddefe4" }}
              >
                <h3
                  style={{ color: "#215352", fontWeight: "bold" }}
                  className="pt-4"
                >
                  Take a hand, lend a hand.{" "}
                </h3>
                <p style={{ color: "#215352" }}>
                  You're not alone. We are a friendly, safe community supporting
                  each other's mental health. {" "}
                </p>
                <button
                  style={{ background: "#215352", color: "#fff" }}
                  className="btn rounded shadow-sm px-5 font-weight-bold"
                  onClick={() => {
                    checkUsr();
                  }}
                >
                  Join Our Community <ArrowRight />{" "}
                </button>
                <br />
                <div className="d-flex flex-column mt-3 pt-md-2 pb-md-4  pl-md-2 justify-content-center">
                  <span
                    style={{ color: "#215352" }}
                    className="text-left font-weight-bold mt-1 ml-3"
                  >
                    {" "}
                    <span style={{ marginRight: 6 }}>👨‍⚕️</span> 24/7 unlimited
                    access {" "}
                  </span>
                  <span
                    style={{ color: "#215352" }}
                    className="text-left font-weight-bold mt-1 ml-3"
                  >
                    <span style={{ marginRight: 6 }}>🕒</span> Right People.
                    Right Care{" "}
                  </span>
                  <span
                    style={{ color: "#215352" }}
                    className="text-left font-weight-bold mt-1 ml-3"
                  >
                    <span style={{ marginRight: 6 }}>🚫</span> No judgment. No
                    stigma. {" "}
                  </span>
                  <span
                    style={{ color: "#215352" }}
                    className="text-left font-weight-bold mt-1 ml-3"
                  >
                    <span style={{ marginRight: 6 }}>🎁 </span> Get recognized
                    for your contributions{" "}
                  </span>
                  <span
                    style={{ color: "#215352" }}
                    className="text-left font-weight-bold mt-1 ml-3"
                  >
                    <span style={{ marginRight: 6 }}>🛅 </span>Secure and
                    Reliable{" "}
                  </span>
                </div>
              </div>
            </div>

            {showGuidelines && (
              <>
                {step == 0 && (
                  <div>
                    <Modal size="lg" centered scrollable show={showGuidelines}>
                      <Modal.Header
                        style={{
                          margin: "0 auto",
                          fontSize: 25,
                          fontWeight: "bold",
                        }}
                      >
                        Welcome to the CareMe Community 🎉!{" "}
                      </Modal.Header>
                      <Modal.Body className="px-3">
                        <h5
                          style={{ fontFamily: "Poppins-SemiBold" }}
                          className="text-center"
                        >
                          To make this a safe environment for everyone, we ask
                          that you adhere to the following community guidelines.
                        </h5>
                        <div className="px-3">
                          <br />
                          <p>
                            <b> Be kind and thoughtful ❤️</b> <br />
                            This is a place to lift each other up. There is no
                            room for discriminatory, violent, threatening,
                            hateful, judgmental, exclusionary, or insulting
                            language. We expect community members to respond to
                            each other with constructive, positive comments that
                            they would be happy to receive themselves. We’re all
                            on the same team, and it should show.
                            <br />
                            <br />
                            <b>Respect anonymity 🙏</b> <br />
                            Anonymity is crucial to make everyone feel
                            comfortable participating in our community. We will
                            remove any comments mentioning private people or
                            sharing/asking for personally identifiable
                            information. <br />
                            <br />
                            <b>Avoid medical advice 💊</b> <br />
                            While we encourage you to share your experiences
                            about clinicians and medications, if you have
                            specific medical questions, please ask your
                            physician or therapist. Posts in the community
                            forum, from moderators or members, should not be
                            taken as medical advice. <br />
                            <br />
                            <b>Avoid promotional posting 🚫</b> <br />
                            Comments that promote social media accounts,
                            businesses, products/services, and personal blogs
                            distract from our mission and will be removed.
                            CareMe moderators will post articles for discussion,
                            but other than that we ask that you please refrain
                            from posting URLs.
                          </p>
                        </div>
                        <a
                          className="guideline_behaviour"
                          href="#"
                          target="_blank"
                        >
                          Learn More about our community guidelines
                        </a>
                      </Modal.Body>
                      <div className="d-flex flex-column justify-content-center my-3">
                        <div className="mx-auto col-md-6 my-1">
                          <button
                            style={{
                              background: "#215352",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            onClick={() => setCheck(!check)}
                            className={`btn btn-block p-2`}
                          >
                            {check ? <CheckSquare /> : <Square />} I agree to
                            community rules
                          </button>
                        </div>
                        <div className="mx-auto col-md-6 mt-md-1 mt-1">
                          <button
                            disabled={!check}
                            style={{
                              background: "#215352",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              setStep(step + 1);
                              {
                                setCheck(false);
                              }
                            }}
                            className={`btn btn-block p-2`}
                          >
                            {check ? "CONTINUE" : "AGREE TERMS & CONDITIONS"}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                )}
                {step == 1 && (
                  <div>
                    <Modal className="" centered show={showGuidelines}>
                      <Modal.Header style={{ margin: "0 auto" }}>
                        <h4
                          className="text-center font-weight-bold"
                          style={{ fontFamily: "Sours-pro" }}
                        >
                          Give Yourself A NickName
                        </h4>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          type="text"
                          className="guide_nickname form-control py-5"
                          defaultValue={nickName}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter your name here..."
                        />
                        <h4 style={{ marginTop: 20 }}>[or]</h4>
                        <div className="col-md-5 mx-auto my-2 mt-4">
                          <button
                            style={{
                              background: "#215352",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              setNoname();
                            }}
                            className={`btn btn-block p-2`}
                          >
                            {!nickName && check ? <CheckSquare /> : <Square />}{" "}
                            Stay Anonymous
                          </button>
                        </div>
                        <div className="col-md-5 mx-auto mt-2">
                          <button
                            disabled={!check}
                            style={{
                              background: "#215352",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              setStep(step + 1);
                              {
                                setCheck(false);
                              }
                            }}
                            className={`btn btn-block p-2`}
                          >
                            CONTINUE
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
                {step == 2 && (
                  <div style={{ margin: "0 auto" }}>
                    <Modal className="" scrollable show={showGuidelines}>
                      <Modal.Header style={{ margin: "0 auto" }}>
                        <h4
                          className="text-center font-weight-bold"
                          style={{ fontFamily: "Sours-pro" }}
                        >
                          Select Your Avatar
                        </h4>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="avatar_container">
                          <ul className="avatar_cards">
                            {avatar &&
                              avatar.map((i, index) => {
                                return (
                                  <li key={index} className="avatar_card">
                                    <img
                                      className={`${
                                        i.select ? "back_img" : null
                                      }`}
                                      onClick={() => setImg(i.id)}
                                      src={i.src}
                                      alt=""
                                    />
                                    {i.select ? (
                                      <AiFillCheckSquare
                                        className="inside_icon"
                                        size={18}
                                        color={"green"}
                                      />
                                    ) : null}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        {careprofile && (
                          <>
                            {" "}
                            <h4 style={{ marginTop: 20 }}>[or]</h4>
                            <div className="col-md-6 mx-auto my-2 mt-4">
                              <button
                                style={{
                                  background: "#215352",
                                  color: "#fff",
                                  fontWeight: "bold",
                                }}
                                onClick={() => setCheck(!check)}
                                className={`btn btn-block p-2`}
                              >
                                {check ? <CheckSquare /> : <Square />} Use
                                CareMe Profile
                              </button>
                            </div>
                          </>
                        )}
                        <div className="col-md-6 mx-auto mt-4">
                          <button
                            style={{
                              background: "#215352",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            disabled={!check}
                            onClick={() => {
                              sendData();
                            }}
                            className={`btn btn-block p-2`}
                          >
                            Continue
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
              </>
            )}
            {step == 3 && <Loader />}
          </div>
        </div>
      )}
    </>
  );
};

export default CommunityHome;
