import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Store from '../Store';
import App from '../App';
import HomePage from "../Components/Layouts/Home/HomePage";
import Profile from "../Components/Layouts/Profile/Profile";
import HomeProfile from "../Components/Layouts/Profile/HomeProfile";
import AddProfile from "../Components/Layouts/Profile/AddProfile";
import ServicePlan from "../Components/Layouts/Home/ServiceSection/ServicePlan";

import PrivateRoute from "./PrivateRoute";
import CustomNavbar from "../Components/Layouts/CustomNavbar";
import ProfileMain from "../Components/Layouts/Profile/ProfileMain";
import Archive from "../Components/Layouts/Profile/Archive";
import Index from "../Components/Layouts/GetCare/Index";
import  RIndex  from "../Components/Layouts/Reschedule/Index";
import  SIndex  from "../Components/Layouts/Reschedule/Schedule/Index";
import  SAIndex  from "../Components/Layouts/TherapyJourney/ScheduleAppointment/index";
import  TIndex  from "../Components/Layouts/TherapyJourney/Index";
import BillingHistory from "../Components/Layouts/Profile/BillingHistory";
import AppointmentHistory from "../Components/Layouts/Profile/AppointmentHistory";
import PaymentStatus from "../Components/Layouts/PaymentStatus";
import FamilyProfile from "../Components/Layouts/Profile/FamilyProfile";
import AppointmentDetails from "../Components/Layouts/AppointmentDetails";
import Payment from "../Components/Layouts/TherapyJourney/Payment";

// import "../assets/scss/common.scss";
// import "../assets/scss/responsive.scss";

//Meeting files

import Error from '../pages/Error'
import Meeting from '../pages/meeting'
import SessionScreen from '../Components/WaitingScreen/SessionScreen';
import ThankScreen from '../Components/AgoraVideoCall/ThanksScreen';
import QuitScreen from '../Components/AgoraVideoCall/QuitScreen';
import AgoraError from '../Components/AgoraVideoCall/AgoraLocalStreamError';
import PermissionDenied from '../Components/AgoraVideoCall/PermissionDenied';
import NetworkDetector from '../NetworkDetector/NetworkDetector';
import ChooseLanguage from "../Components/Layouts/Home/ChooseLanguage";
import SelectDateTime from "../Components/Layouts/TherapyJourney/SelectDateTime";
import TherapyCare from "../Components/Layouts/TherapyJourney/TherapyCare";
import ChatTherapy from "../Components/Layouts/ChatTherapy/ChatTherapy";
import CommunityHome from "../Components/Community/CommunityHome";
import CommentScreen from "../Components/Community/commentScreen";
import Login from "../Components/Layouts/Login/Login";
import { connect } from 'react-redux'; 
import Documents from "../Components/Layouts/Profile/Documents";
import Therapy from "../Components/Layouts/Home/Therapy";
import RegistrationForm from "../Components/Layouts/Login/RegistrationForm";
import AuthUser from "../Components/Layouts/Login/AuthUser";
import AsesQues from "../Components/Layouts/Home/ServiceSection/AsesQues";
import Assesments from "../Components/Layouts/Profile/Assesments";
import TherapyAsses from "../Components/Layouts/TherapyAsses";
import Exercise from "../Components/Layouts/Profile/Exercise";
import Goals from "../Components/Layouts/Profile/Goals";
import Prescription from "../Components/Layouts/Profile/Prescription";
import AgoraCanvas from "../Components/AgoraVideoCall";
import ChatDetails from "../Components/Layouts/Home/ChatDetails";
import WaitingScreen from "../Components/AgoraVideoCall/WaitingScreen/WaitingScreen";
import Chatpay from "../Components/Layouts/Home/Chatpay";
import Workbook from "../Components/Layouts/Workbook";
import  AddUser  from "../Components/Layouts/AddUser";
import AddSingleUser from "../Components/Layouts/AddSingleUser";
import AddCsvFile from "../Components/Layouts/AddCsvFile";
import wellbeing from "../Components/Layouts/wellbeing";
import Dashboard from "../Components/Layouts/Dashboard";
import wellbeingEmployee from "../Components/Layouts/wellbeingEmployee";
import wellbeingprofile from "../Components/Layouts/Profile/wellbeingprofile";
import wellbeingtn from "../Components/Layouts/wellbeingtn";
import TrackView from "../Components/Layouts/wellbeingtrack";
import TrackViewEnglish from "../Components/Layouts/wellbeintrackenglish";
import GetuserLanguage from "../Components/Layouts/getuserLanguage";
import NmDashboard from "../Components/Layouts/NmDashboard";
import AssesResults from "../Components/Layouts/AssesResults";
// import    from "../Components/Layouts/AddUser";
// import  AddUser  from "../Components/Layouts/AddUser";


const createHistory = require("history").createBrowserHistory;
export const history = createHistory();

function ProviderComponent() {
  return (
    <Provider store={Store}>
    <Router history={history}>
        <Switch>
       
          <Route exact={true} path="/" component={Error} /> 
          <PrivateRoute  path="/home" component={HomePage} />
          <PrivateRoute  path="/addprofile" component={AddProfile} />
          <PrivateRoute  path="/profile" component={HomeProfile} />
          <PrivateRoute  path="/userProfile" component={Profile} /> 
          <PrivateRoute  path="/familyprofile" component={FamilyProfile} />    
          <PrivateRoute  path="/service/:productId?" component={ServicePlan} />
          <PrivateRoute  path="/getcare" component={Index} /> 
          <PrivateRoute  path="/getcare/payment" component={Index} /> 
          <PrivateRoute  path="/reschedule/:productId/:empId?" component={RIndex} />  
          <PrivateRoute  path="/schedule/:productId" component={SIndex} />  
          <PrivateRoute  path="/scheduleapp/:productId/:empId" component={SAIndex} />  
          <PrivateRoute exact={true} path="/therapycare" component={TherapyCare} />  
          <PrivateRoute  path="/history/billing" component={BillingHistory} />      
          <PrivateRoute  path="/history/appointment" component={AppointmentHistory} />
          <PrivateRoute  path="/paymentstatus" component={PaymentStatus} />
          <PrivateRoute  path="/appointmentdetails" component={AppointmentDetails} /> 
          <PrivateRoute  path="/changelanguage" component={ChooseLanguage} /> 
          <PrivateRoute  path="/chattherapy" component={ChatTherapy} /> 
          <PrivateRoute  path="/comment/:id" component={CommentScreen} /> 
          <PrivateRoute  path="/user_documents" component={Documents} /> 
          <PrivateRoute  path="/assesments" component={Assesments} /> 
          <PrivateRoute  path="/exercise" component={Exercise} /> 
          <PrivateRoute  path="/goals" component={Goals} /> 
          <PrivateRoute  path="/prescription" component={Prescription} /> 
          <PrivateRoute  path="/offerings" component={Therapy} /> 
          <PrivateRoute  path="/billing" component={AppointmentDetails} /> 
          <PrivateRoute  path="/chatscreen" component={AgoraCanvas} /> 
          <PrivateRoute exact={true} path="/therapyjourney/selectSession/" component={TIndex} />
          <PrivateRoute exact={true} path="/therapyjourney/selectdatetime" component={SelectDateTime} /> 
          <PrivateRoute path="/communities" component={CommunityHome} /> 
          <PrivateRoute path="/chatdetails" component={ChatDetails} /> 
          <PrivateRoute path="/waitingscreen" component={WaitingScreen} /> 
          <PrivateRoute  path="/workbook" component={Workbook} /> 
          <PrivateRoute  path="/admin-panel" component={AddUser} /> 
          <PrivateRoute  path="/create-user" component={AddSingleUser} /> 
          <PrivateRoute  path="/upload-users" component={AddCsvFile} /> 
          <PrivateRoute  path="/carepay" component={Payment} /> 
          <PrivateRoute path="/chatpay" component={Chatpay} /> 
          <PrivateRoute path="/dashboard" component={Dashboard} /> 
          <PrivateRoute  path="/dashboard-nm" component={NmDashboard} /> 
          <PrivateRoute path="/wellbeing-user-profile" component={wellbeingprofile} /> 
          {/* <Route path="/meeting/:user/:id" component={Meeting} /> */}
          <Route exact={true} path="/:id/:id/auth/user" component = {AuthUser}/>
            {/* <Route path="/:user/:userId" component={WaitingScreen} /> */}
            <Route path="/community" component={CommunityHome} />
            <Route path="/wellbeing-tn-asses-view" component={TrackView} />
            <Route path="/wellbeing-asses-view" component={TrackViewEnglish} />
            <Route path="/community/ptsd" component={CommunityHome} />
            <Route path="/careme-assessment/wellbeing/:id" component={GetuserLanguage} />
            <Route path="/community/ocd" component={CommunityHome} />
            <Route path="/community/relationship" component={CommunityHome} />
            <Route path="/community/lgbtiq" component={CommunityHome} />
            <Route path="/wellbeing-tn-asses" component={wellbeingtn} /> 
            <Route path="/community/depression" component={CommunityHome} />
            <Route path="/thanks" component = {ThankScreen}/>
            <Route path="/quit" component = {QuitScreen}/>
            <Route path="/permission" component = {PermissionDenied}/>
            <Route path="/error" component = {AgoraError}/>
            <Route  path="/pendingQues" component={RegistrationForm} /> 
            <Route  path="/therapyAsses" component={TherapyAsses} /> 
            <Route  path="/wellbeing-profile" component={wellbeing} /> 
            <Route  path="/wellbeing-assessment" component={wellbeingEmployee} /> 
            <Route path="/assesment-result-view" component={AssesResults} />
            <Route  component={Error} />
        </Switch>      
    </Router>
  </Provider>
 )  
}


export default ProviderComponent;
